import cookie from "~/utils/cookie";
import {ApiRequest} from "~/utils/api-request";

export default {

    isAuth: async (onSuccess = () => {}, onFail = () => {}) => {
        if (!process.client) {
            onFail();

            return null;
        }

        if (!cookie.authToken.value()) {
            onFail();

            return;
        }

        let apiRequest = new ApiRequest('get', 'auth/is-auth');

        apiRequest.onSuccess(() => {
            onSuccess();
        });

        apiRequest.onError(() => {
            onFail();
        });

        return await apiRequest.request();
    },

    extendAuthToken: async (onSuccess = () => {}, onFail = () => {}) => {
        if (!process.client) {
            onFail();

            return null;
        }

        let apiRequest = new ApiRequest('post', 'auth/extend-token', null);

        apiRequest.onSuccess(() => {
            onSuccess();
        });

        apiRequest.onError(() => {
            onFail();
        });

        return await apiRequest.request();
    }

}